export const hostURL = 'http://t-games.com.ua/server';
// export const hostURL = 'http://localhost/business_game_server';

export const params = {
	mode: 'cors',
	headers: {
		'Content-Type': 'application/json'
	}
};

export const networkStatuses = {
	PENDING: 'PENDING',
	SUCCESS: 'SUCCESS',
	FAIL: 'FAIL'
};