export const marketCardList = [
	{
		id: 1,
		name: 'Покупець на 2-кімнатну квартиру',
		info: `Пропонує <b>$45 000</b> за 2-кімнатну квартиру, що здається в оренду. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},	
	{
		id: 2,
		name: 'Покупець на елітне житло',
		info: `Ріелтор пропонує <b>$30 000</b> за кожну квартиру в елітних будинках на 
			12 і більше квартир. Покупець є податковим резидентом Європейського Союзу.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 3,
		name: 'Покупець на 3-кімнатну квартиру',
		info: `Пропонує <b>$110 000</b> за 3-кімнатну квартиру, що здається в оренду. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 4,
		name: 'Покупець на 3-кімнатну квартиру',
		info: `Пропонує <b>$135 000</b> за 3-кімнатну квартиру, що здається в оренду. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 5,
		name: 'Покупець на Мережу вегетаріанських ресторанів швидкого харчування ТОВ',
		info: `Поглинання мережі вегетаріанських ресторанів крупним гравцем у цій сфері, 
			колишнім конкурентом. Пропонує <b>вдвічі більше</b> за очікувану суму.`,
		caption: ``,
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 6,
		name: 'Покупець на котеджі',
		info: `Покупець пропонує по <b>$38 000</b> за кожен підїзд у котеджах 
			К-2, К-4, К-8. Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 7,
		name: 'Покупець на 2-кімнатну квартиру',
		info: `Пропонує <b>$55 000</b> за 2-кімнатну квартиру, що здається в оренду. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 8,
		name: 'Покупець на котеджі',
		info: `Покупець пропонує по <b>$40 000</b> за кожен підїзд у котеджах 
			К-2, К-4, К-8. Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 9,
		name: 'Покупець на Мережу електричних Автозаправних станцій',
		info: `Покупець терміново шукає мережу елетричних автозаправних станцій. Готовий 
			заплатити <b>$140 000</b> готівкою.`,
		caption: '',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 10,
		name: 'Покупець на 3-кімнатну квартиру',
		info: `Пропонує <b>$100 000</b> за 3-кімнатну квартиру, що здається в оренду. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 11,
		name: 'ТОВ продано',
		info: `Бізнес продано. Інші співзасновники вже продали свої частки і нові власники 
			хочуть викупити твою частку.`,
		caption: `Тобі пропонують готівкою <b>втричі більше</b> вартості твоєї частки. 
			Дійсно для всіх ТОВ.`,
		subCaption: `Якщо ти продаєш, відміни дохід від цієї власності зі свого ПД.`
	},
	{
		id: 12,
		name: 'Покупець на 3-кімнатну квартиру',
		info: `Пропонує <b>$135 000</b> за 3-кімнатну квартиру, що здається в оренду. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 13,
		name: 'Покупець на 3-кімнатну квартиру',
		info: `Твій бізнес-партнер програв у букмекерській конторі всю готівку, зробивши 
			ставку на виграш улюбленої футбольної команди. Обіцяє виплатити тобі $100 000 
			через чотири роки. Може сплачувати щомісячно, але не може сплатити завдатку.`,
		caption: '',
		subCaption: `Якщо ти продаєш на цих усовах, твій ПД знижується на $500 на
			місяць, поки ти не отримаєш з нього $100 000.`
	},
	{
		id: 14,
		name: 'Покупець на елітне житло',
		info: `Ріелтор пропонує <b>$45 000</b> за квартири всіх типів в елітному будинку 
			будь-якого розміру. Сплачує готівкою. Гроші є, хоче інвестувати.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 15,
		name: 'Колекціонер шукає золоті монети',
		info: `Відомий колекціонер розшукує справжні рідкісні монети, викарбувані Іспанським 
			Королівським монетним двором Філіпа ІІ. Готовий віддати <b>$5 000</b> готівкою 
			за кожну монету.`,
		caption: ``,
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 16,
		name: 'Покупець на Завод з виробництва штучного мармуру з бетону',
		info: `Готовий заплатити <b>$450 000</b> і взяти на себе всі витрати на переоформлення 
			документів на іншого власника.`,
		caption: ``,
		subCaption: ``
	},
	{
		id: 17,
		name: 'Блокчейн-проект',
		info: `Успішний Блокчейн-проект дав приріст капіталу інвесторам. Кожен інвестор, в кого 
			є $100 000 в інвестиціях, отримує <b>$1 000</b> ПД. Засновик проекту отримує пасивний 
			дохід <b>$5 000</b> від мережі та <b>$2 000</b> від своїх інвестицій, якщо 
			у тебе є $200 000.`,
		caption: ``,
		subCaption: `Якщо ти бажаєш продати цей бізнес, можеш продати лише одному з гравців.`
	},
	{
		id: 18,
		name: 'Покупець на котеджі',
		info: `Покупець пропонує по <b>$28 000</b> за кожен підїзд у котеджах К-2, К-4, К-8. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 19,
		name: 'Покупець на Малий бізнес з пошиття одягу',
		info: `Український бренд одягу хоче викупити цех з виробництва одягу для 
			індивідуального пошиття для своїх клієнтів. Пропонує <b>$50 000</b> з викупом 
			швейного обладнання.`,
		caption: ``,
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 20,
		name: 'Покупець на Торговий центр',
		info: `Найбільша фірма, власник мережі торгових центрів, заходить у твоє місто. 
			Купує невеликі торгові центри. Сплачує <b>$150 000</b> за кожен центр.`,
		caption: 'Кожен, хто має торговий центр, може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 21,
		name: 'Інфляція зростає!',
		info: `Інфляція наближається до 10%. Процентні ставки за накладними піднімаються 
			на 20%. Ти вкладав в закладні з плаваючою відсотковою ставкою, тому ВТРАЧАЄШ 
			ПРАВО ВИКУПУ на всі 3-кімнатні квартири, якими володієш, і МАЄШ ПОВЕРНУТИ їх банку.`,
		caption: `Ти втрачаєш пасивний дохід від власності.`,
		subCaption: `Інших гравців не стосується.`
	},
	{
		id: 22,
		name: 'Покупець на 20 акрів землі',
		info: `Забудовник шукає ділянки землі розміром 20 акрів. Планує розбивати їх на ділянки 
			під житлове будівництво на продаж. Пропонує <b>$200 000</b> кожному, хто володіє 
			20 акрами землі у житловій зоні.`,
		caption: ``,
		subCaption: ``
	},
	{
		id: 23,
		name: 'Ціни на золото злетіли!',
		info: `Ринок золота досяг нових історичних максимумів. Ціна на дорогоцінні метали 
			зросла до <b>$2 000</b> за унцію.`,
		caption: ``,
		subCaption: `Кожен, хто має 1-унцеві золоті монети, може продати за цією ціною.`
	},
	{
		id: 24,
		name: 'Покупець на 3-кімнатну квартиру',
		info: `Пропонує <b>$150 000</b> за 3-кімнатну квартиру, що здається в оренду. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 25,
		name: 'Покупець на 10 акрів землі',
		info: `Власник конезаводу реорганізував бізнес у реабілітаційний центр іпотерапії 
			для дітей з аутизмом. Шукає 10 акрів землі далеко від мегаполісу в природній зоні. 
			Готовий заплатити <b>$150 000</b> кожному, хто володіє такою власністю.`,
		caption: ``,
		subCaption: ``
	},
	{
		id: 26,
		name: 'Покупець на котеджі',
		info: `Покупець пропонує по <b>$25 000</b> за кожен підїзд у котеджах К-2, К-4, К-8. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 27,
		name: 'Покупець на котеджі',
		info: `Покупець пропонує по <b>$36 000</b> за кожен підїзд у котеджах К-2, К-4, К-8. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 28,
		name: 'Покупець на колекцію картин',
		info: `Твої знайомства з сильними світу цього через 15 років привели тебе в компанію 
			мільйонерів галеристів-колекціонерів, які скуповують ранні картини сучасних віжлмих 
			авторів-художників початку 21 століття з інвестиційно-спекулятивними цілями. Твоя 
			колекція буде виставлена у найкращих музеях та галереях Європи. І, можливо, буде 
			розпродана з аукціону. Хороша пропозиція тобі як власнику цілої колекції 
			рідкісних робіт.`,
		caption: `<b>$25 000</b> за кожну, якщо робіт менше 10.`,
		subCaption: `<b>$30 000</b> за кожну, якщо робіт понад 10.`
	},
	{
		id: 29,
		name: 'Покупець на елітне житло',
		info: `Пропонує <b>$45 000</b> за квартири всіх типів в елітному будинку 
			будь-якого розміру. Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 30,
		name: 'Покупець на 2-кімнатну квартиру',
		info: `Пропонує <b>$60 000</b> за 2-кімнатну квартиру, що здається в оренду. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 31,
		name: 'Зниження процентних ставок!',
		info: `Процентні ставки за закладними знижуються до 5%. Якщо ти володієш 3-кімнатною 
			квартирою, можеш її продати <b>на $50 000 дорожче</b> за ту ціну, яка була 
			заявлена при купівлі.`,
		caption: `Інших гравців не стосується.`,
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 32,
		name: 'Покупець на котеджі',
		info: `Покупець пропонує по <b>$32 000</b> за кожен підїзд у котеджах К-2, К-4, К-8. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 33,
		name: 'Покупець на котеджі',
		info: `Покупець пропонує по <b>$40 000</b> за кожен підїзд у котеджах К-2, К-4, К-8. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 34,
		name: 'Покупець на котеджі',
		info: `Покупець пропонує по <b>$30 000</b> за кожен підїзд у котеджах К-2, К-4, К-8. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 35,
		name: 'Покупець на Логістичний центр',
		info: `Підприємець у сфері вантажних перевезень хоче масштабувати бізнес та шукає 
			готову пропозицію від логістичних компаній із ліцензією на вантажні перевезення 
			на території Європи. Готовий заплатити <b>$250 000</b>.`,
		caption: ``,
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 36,
		name: 'Покупець на котеджі',
		info: `Покупець пропонує по <b>$35 000</b> за кожен підїзд у котеджах К-2, К-4, К-8. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 37,
		name: 'Покупець на елітне житло',
		info: `Ріелтор пропонує <b>$25 000</b> за квартири всіх типів в елітному будинку 
			будь-якого розміру. Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 38,
		name: 'Нова соціальна мережа Unreal travel',
		info: `На хайпі злетіла кількість передплатників соціальної мережі Unreal travel 
			і твій пасивний дохід від бізнесу зріс до <b>$2 000</b>, не вимагаючи твоєї 
			присутності. Настав час подумати про вкладення вільних грошей.`,
		caption: ``,
		subCaption: ``
	},
	{
		id: 39,
		name: 'Покупець на 3-кімнатну квартиру',
		info: `Пропонує <b>$135 000</b> за 3-кімнатну квартиру, що здається в оренду. Оплата 
			готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 40,
		name: 'Покупець на 3-кімнатну квартиру',
		info: `Пропонує <b>$90 000</b> за 3-кімнатну квартиру, що здається в оренду. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 41,
		name: 'ТОВ продано',
		info: `Бізнес продано. Інші співзасновники вже продали свої частки і нові власники 
			хочуть викупити твою частку.`,
		caption: `Тобі пропонують готівкою <b>вдвічі більше</b> вартості твоєї частки. 
			Дійсно для всіх ТОВ.`,
		subCaption: `Якщо ти продаєш, відміни дохід від цієї власності зі свого ПД.`
	},
	{
		id: 42,
		name: 'Покупець на стартап Content Plan',
		info: `Соціальна мережа пропонує <b>$100 000</b> за платформу для планування та 
			публікації контенту у соціальних мережах.`,
		caption: 'Кожен, хто володієтакою компанією, може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	},
	{
		id: 43,
		name: 'ТОВ продано',
		info: `Бізнес продано. Інші співзасновники вже продали свої частки і нові власники 
			хочуть викупити твою частку.`,
		caption: `Тобі пропонують готівкою <b>вдвічі більше</b> вартості твоєї частки. 
			Дійсно для всіх ТОВ.`,
		subCaption: `Якщо ти продаєш, відміни дохід від цієї власності зі свого ПД.`
	},
	{
		id: 44,
		name: 'Покупець на 2-кімнатну квартиру',
		info: `Пропонує <b>$65 000</b> за 2-кімнатну квартиру, що здається в оренду. 
			Оплата готівкою.`,
		caption: 'Кожен може продати за цією ціною.',
		subCaption: `Якщо ти продаєш, сплати закладну в банк і відміни дохід від цієї 
			власності зі свого ПД.`
	}
];