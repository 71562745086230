// REAL_ESTATE - нерухомість 

export const bigAgreementTypes = {
	REAL_ESTATE: 'REAL_ESTATE',
	STOP_GAME: 'STOP_GAME',
	BUSINESS: 'BUSINESS'
};

export const bigAgreementList = [
	{
		id: 1,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на чотири родини К-4',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$140 000' },
			{ title: 'ЗАКЛАДНА', caption: '$108 000' },
			{ title: 'ЗАВДАТОК', caption: '$32 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$2 000' }
		],
		caption: `Власниця вийшла заміж і виїжджає на постійне місце проживання до Каліфорнії.
		 Продає актив у стислі терміни за ціною, нижчою від ринкової на 30%.`,
		subCaption: 'Зможеш продати від <b>$100 000</b> до <b>$160 000</b>.  Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 2,
		type: bigAgreementTypes.STOP_GAME,
		name: 'СТОП ГРА',
		captionList: [
			`<b>Аудиторська консультація перед податковою перевіркою.</b>
			 Усі гравці перевіряють балансові звіти <b>сусіда зліва.</b> `,
			`При знаходженні помилок більше <b>$1 000</b> у веденні ігрового балансу
			 (за невідповідності Активів, Пасивів, Грошових Потоків, Кредитів)
			  ти виплачуєш аудитору <b>$5 000</b>.`,
			`Втішайся тим, що податкова перевірка обійшлась би значно дорожче.`
		],
		isSellAble: false
	},
	{
		id: 3,
		type: bigAgreementTypes.BUSINESS,
		name: 'Американська бургерна мережа',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$40 000' },
			{ title: 'ЗАКЛАДНА', caption: '$0' },
			{ title: 'ЗАВДАТОК', caption: '$40 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$1 300' }
		],
		caption: `ТОВ розширює кількість фаст-фудів та залучає партнерів.`,
		subCaption: 'Зможеш продати від <b>$80 000</b> до <b>$120 000</b>.  Використай шанс сам або продай іншому гравцю.',
		isSellAble: false
	},
	{
		id: 4,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на вісім родин К-8',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$125 000' },
			{ title: 'ЗАКЛАДНА', caption: '$100 000' },
			{ title: 'ЗАВДАТОК', caption: '$25 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$1 800' }
		],
		caption: `Обєкт після будівельників. Потрібне додаткове фінансування.`,
		subCaption: 'Зможеш продати від <b>$200 000</b> до <b>$320 000</b>.  Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 5,
		type: bigAgreementTypes.BUSINESS,
		name: 'Американська бургерна мережа',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$160 000' },
			{ title: 'ЗАКЛАДНА', caption: '$120 000' },
			{ title: 'ЗАВДАТОК', caption: '$40 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$1 100' }
		],
		caption: `Логістична компанія, що займається автоперевезеннями на території Європи,
		 змінює стратегію і хоче залучити інвестиції для здійснення доставок авіатранспортом.
		  Зацікавлює партнерів.`,
		subCaption: 'Зможеш продати від <b>$120 000</b> до <b>$250 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 6,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається 3-кімнатна квартира',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$125 000' },
			{ title: 'ЗАКЛАДНА', caption: '$105 000' },
			{ title: 'ЗАВДАТОК', caption: '$20 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '-$100' }
		],
		caption: `Квартира під ключ в елітному районі з розвиненою інфраструктурою.`,
		subCaption: 'Зможеш продати від <b>$65 000</b> до <b>$135 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 7,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на чотири родини К-4',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$100 000' },
			{ title: 'ЗАКЛАДНА', caption: '$80 000' },
			{ title: 'ЗАВДАТОК', caption: '$20 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$8 000' }
		],
		caption: `Обєкт знаходиться в зоні реконструкції старого житла. Відремонтований. Готовий
		 під здачу в оренду. Прогнозують зростання цін від продажу активу на 35%.`,
		subCaption: 'Зможеш продати від <b>$100 000</b> до <b>$160 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 8,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на вісім родин К-8',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$200 000' },
			{ title: 'ЗАКЛАДНА', caption: '$160 000' },
			{ title: 'ЗАВДАТОК', caption: '$40 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$1 600' }
		],
		caption: `У власника негаразди із законом, через що цей котедж продається індивідуально.
		 Ненадійний позичальник, закладна не сплачена.`,
		subCaption: 'Зможеш продати від <b>$200 000</b> до <b>$320 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 9,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається 3-кімнатна квартира',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$70 000' },
			{ title: 'ЗАКЛАДНА', caption: '$50 000' },
			{ title: 'ЗАВДАТОК', caption: '$20 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$500' }
		],
		caption: `Після розлучення власник продає квартиру у галасливому кварталі. Вікна виходять на
		 центральну вулицю. Виставлена на продаж 4 місяці тому.`,
		subCaption: 'Зможеш продати від <b>$65 000</b> до <b>$135 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 10,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається елітна нерухомість',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$350 000' },
			{ title: 'ЗАКЛАДНА', caption: '$300 000' },
			{ title: 'ЗАВДАТОК', caption: '$50 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$2 400' }
		],
		caption: `12-квартирний житловий будинок на балансі закритого акціонерного товариства, яке 
		вирішило продати обєкт та на прибуток побудувати медичну клініку для онкохворих дітей 
		із залученням ізраїльських фахівців та передових технологій.`,
		subCaption: 'Зможеш продати від <b>$300 000</b> до <b>$540 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 11,
		type: bigAgreementTypes.BUSINESS,
		name: 'Медична клініка імплантології ТОВ',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$30 000' },
			{ title: 'ЗАКЛАДНА', caption: '$0' },
			{ title: 'ЗАВДАТОК', caption: '$30 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$900' }
		],
		caption: `Власник вирішив передати 40% своєї клініки підприємцю за умов повного 
		управління клінікою новим партнером.`,
		subCaption: 'Зможеш продати від <b>$60 000</b> до <b>$90 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 12,
		type: bigAgreementTypes.BUSINESS,
		name: 'Мережа вегетаріанських ресторанів швидкого харчування ТОВ',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$20 000' },
			{ title: 'ЗАКЛАДНА', caption: '$0' },
			{ title: 'ЗАВДАТОК', caption: '$20 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$800' }
		],
		caption: `Вже зарекомендувала себе в Європі і хоче увійти до топ-10 найбільших мереж 
		вегетаріанського харчування світу. Залучають аукціонерів.`,
		subCaption: 'Зможеш продати від <b>$80 000</b> до <b>$120 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 13,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається 3-кімнатна квартира',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$75 000' },
			{ title: 'ЗАКЛАДНА', caption: '$68 000' },
			{ title: 'ЗАВДАТОК', caption: '$7 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$300' }
		],
		caption: `У будинку поряд із розважальним центром. Можливе зростання цін плюс пасивний дохід 
		вже зараз. Орендна плата.`,
		subCaption: 'Зможеш продати від <b>$65 000</b> до <b>$135 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 14,
		type: bigAgreementTypes.BUSINESS,
		name: 'Мережа електричних автозаправних станцій',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$125 000' },
			{ title: 'ЗАКЛАДНА', caption: '$100 000' },
			{ title: 'ЗАВДАТОК', caption: '$25 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$1 800' }
		],
		caption: `Власник вирішив продати бізнес і вкласти гроші від продажу в завод із переробки відходів.`,
		subCaption: 'Зможеш продати від <b>$60 000</b> до <b>$140 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 15,
		type: bigAgreementTypes.BUSINESS,
		name: 'Завод з виготовлення паркетної дошки',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$120 000' },
			{ title: 'ЗАКЛАДНА', caption: '$80 000' },
			{ title: 'ЗАВДАТОК', caption: '$40 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$1 200' }
		],
		caption: `Бізнес існує у Європі 3,5 роки і для розширення потрібні вливання інвестицій на умовах 
		меншого % ніж позика у банку з можливістю контролю за управлінням бізнесу. Розширення 
		міжнародної мережі. Залучають інвестора.`,
		subCaption: 'Зможеш продати від <b>$90 000</b> до <b>$150 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 16,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається елітна нерухомість',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$550 000' },
			{ title: 'ЗАКЛАДНА', caption: '$500 000' },
			{ title: 'ЗАВДАТОК', caption: '$50 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$2 800' }
		],
		caption: `Новий 24-квартирний комплекс на пустирі без інфраструктури.`,
		subCaption: 'Зможеш продати від <b>$600 000</b> до <b>$1 800 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 17,
		type: bigAgreementTypes.BUSINESS,
		name: 'Міжнародна страхова група шукає партнера у твоєму регіоні',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$100 000' },
			{ title: 'ЗАКЛАДНА', caption: '$80 000' },
			{ title: 'ЗАВДАТОК', caption: '$20 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$700' }
		],
		caption: `Розширення міжнародної мережі. Залучають інвестора на умовах 30% участі у бізнесі.`,
		subCaption: 'Зможеш продати від <b>$60 000</b> до <b>$120 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 18,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається елітна нерухомість',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$1 200 000' },
			{ title: 'ЗАКЛАДНА', caption: '$1 000 000' },
			{ title: 'ЗАВДАТОК', caption: '$200 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$11 000' }
		],
		caption: `60-квартирний комплекс продається державним житловим фондом, який позбавив власника-
		забудовника прав на користування.`,
		subCaption: 'Зможеш продати від <b>$1 500 000</b> до <b>$2 700 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 19,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на дві родини К-2',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$60 000' },
			{ title: 'ЗАКЛАДНА', caption: '$54 000' },
			{ title: 'ЗАВДАТОК', caption: '$6 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$300' }
		],
		caption: `Відгороджений котедж на дві родини, в екологічно чистій зоні. Юридично підготовлений 
		до продажу, податки сплачені.`,
		subCaption: 'Зможеш продати від <b>$50 000</b> до <b>$80 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 20,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на чотири родини К-4',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$80 000' },
			{ title: 'ЗАКЛАДНА', caption: '$60 000' },
			{ title: 'ЗАВДАТОК', caption: '$20 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$400' }
		],
		caption: `Актив перебуває на балансі банку, відчужений актив за невиплату власником кредиту банку.`,
		subCaption: 'Зможеш продати від <b>$100 000</b> до <b>$160 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 21,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на дві родини К-2',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$50 000' },
			{ title: 'ЗАКЛАДНА', caption: '$42 000' },
			{ title: 'ЗАВДАТОК', caption: '$8 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$240' }
		],
		caption: `Власник котеджа бажає переїхати в ьільший будинок у звязку із попвненням в родині. Орендарі, 
		що залишились, утримують в порядку галявину, сад та горожу.`,
		subCaption: 'Зможеш продати від <b>$50 000</b> до <b>$80 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 22,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на дві родини К-2',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$45 000' },
			{ title: 'ЗАКЛАДНА', caption: '$37 000' },
			{ title: 'ЗАВДАТОК', caption: '$8 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$320' }
		],
		caption: `Власник по вуха в боргах по бізнесу, продає котедж нижче на 30% за ринкову вартість.`,
		subCaption: 'Зможеш продати від <b>$50 000</b> до <b>$80 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 23,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на чотири родини К-4',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$90 000' },
			{ title: 'ЗАКЛАДНА', caption: '$75 000' },
			{ title: 'ЗАВДАТОК', caption: '$15 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$700' }
		],
		caption: `Старий будинок поряд з новою автотрасою. Власник, що проживає в ньому, бажає переїхати 
		в тихіше місце. Ціна зі знижкою для швидкого продажу.`,
		subCaption: 'Зможеш продати від <b>$100 000</b> до <b>$160 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 24,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається 3-кімнатна квартира',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$70 000' },
			{ title: 'ЗАКЛАДНА', caption: '$61 000' },
			{ title: 'ЗАВДАТОК', caption: '$9 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$300' }
		],
		caption: `Хороший інвестеційний потенціал, якщо маєш кошти для вкладення. Позитивний пасивний 
		дохід навіть за низької орендної плати.`,
		subCaption: 'Зможеш продати від <b>$65 000</b> до <b>$135 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 25,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається 3-кімнатна квартира',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$67 000' },
			{ title: 'ЗАКЛАДНА', caption: '$55 000' },
			{ title: 'ЗАВДАТОК', caption: '$12 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$400' }
		],
		caption: `Власник є нерезидентом країни. Здає в оренду квартиру. Звязок через керуючу компанію.`,
		subCaption: 'Зможеш продати від <b>$65 000</b> до <b>$135 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 26,
		type: bigAgreementTypes.BUSINESS,
		name: 'Фермерське господарство ТОВ',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$50 000' },
			{ title: 'ЗАКЛАДНА', caption: '$0' },
			{ title: 'ЗАВДАТОК', caption: '$50 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$1 300' }
		],
		caption: `На розширення земельних ділянок під фермерське господарство потрібне фінансувння. 
		Банки відмовили, бо є незакриті кредити в сезоні із поганим врожаєм через часті опади.`,
		subCaption: 'Зможеш продати від <b>$80 000</b> до <b>$120 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 27,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на дві родини К-2',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$70 000' },
			{ title: 'ЗАКЛАДНА', caption: '$63 000' },
			{ title: 'ЗАВДАТОК', caption: '$7 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$140' }
		],
		caption: `Котедж розташований у елітному селищі. Власник планує залишити бізнес 
		та перебраися до онуків.`,
		subCaption: 'Зможеш продати від <b>$50 000</b> до <b>$80 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 28,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається 3-кімнатна квартира',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$65 000' },
			{ title: 'ЗАКЛАДНА', caption: '$58 000' },
			{ title: 'ЗАВДАТОК', caption: '$7 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$150' }
		],
		caption: `Бізнесмен, що продає квартиру, потребує готівки, щоб зберегти свій бізнес. 
		Наразі квартира зайнята проблемним орендарем, виселення через суд.`,
		subCaption: 'Зможеш продати від <b>$65 000</b> до <b>$135 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 29,
		type: bigAgreementTypes.BUSINESS,
		name: 'Комплекс виробництв дитячого харчування',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$100 000' },
			{ title: 'ЗАКЛАДНА', caption: '$70 000' },
			{ title: 'ЗАВДАТОК', caption: '$30 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$1 500' }
		],
		caption: `Власник залучає партнера до розширення бізнесу. На умовах 30% участі у бізнесі.`,
		subCaption: 'Зможеш продати від <b>$70 000</b> до <b>$120 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 30,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на дві родини К-2',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$60 000' },
			{ title: 'ЗАКЛАДНА', caption: '$48 000' },
			{ title: 'ЗАВДАТОК', caption: '$12 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$400' }
		],
		caption: `Власник котеджу продає його, щоб сплатити рахунки за лікування. Вигідна інвестиційна можливість.`,
		subCaption: 'Зможеш продати від <b>$50 000</b> до <b>$80 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 31,
		type: bigAgreementTypes.BUSINESS,
		name: 'Виробництво штучного мармуру з бетону',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$350 000' },
			{ title: 'ЗАКЛАДНА', caption: '$300 000' },
			{ title: 'ЗАВДАТОК', caption: '$50 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$1 500' }
		],
		caption: `Партнер відходить від справ і хоче передати бізнес у надійні руки.`,
		subCaption: 'Зможеш продати від <b>$225 000</b> до <b>$450 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 32,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається 3-кімнатна квартира',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$115 000' },
			{ title: 'ЗАКЛАДНА', caption: '$105 000' },
			{ title: 'ЗАВДАТОК', caption: '$10 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '-$100' }
		],
		caption: `Спадкоємець покійного власника продає квартиру. Поруч школа, дитячий садок та спортклуб.`,
		subCaption: 'Зможеш продати від <b>$65 000</b> до <b>$135 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 33,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на вісім родин К-8',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$220 000' },
			{ title: 'ЗАКЛАДНА', caption: '$180 000' },
			{ title: 'ЗАВДАТОК', caption: '$40 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$1 700' }
		],
		caption: `Приватний підприємець терміново потребує готівки для збереження 
		своєї частки в бізнесі.`,
		subCaption: 'Зможеш продати від <b>$200 000</b> до <b>$320 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 34,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається 3-кімнатна квартира',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$65 000' },
			{ title: 'ЗАКЛАДНА', caption: '$75 000' },
			{ title: 'ЗАВДАТОК', caption: '$8 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$300' }
		],
		caption: `Хороший інвестиційний потенціал, якщо маєш кошти для вкладення. Позитивний 
		пасивний дохід навіть за низької орендної плати.`,
		subCaption: 'Зможеш продати від <b>$65 000</b> до <b>$135 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 35,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається елітна нерухомість',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$550 000' },
			{ title: 'ЗАКЛАДНА', caption: '$475 000' },
			{ title: 'ЗАВДАТОК', caption: '$75 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$3 400' }
		],
		caption: `Продаються дві будівлі, що стоять поруч (всього 24 квартири). Власник вирішив продати актив 
		і відійти від справ.`,
		subCaption: 'Зможеш продати від <b>$600 000</b> до <b>$1 080 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 36,
		type: bigAgreementTypes.BUSINESS,
		name: 'Франшиза на міжнародний готельний бізнес',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$500 000' },
			{ title: 'ЗАКЛАДНА', caption: '$400 000' },
			{ title: 'ЗАВДАТОК', caption: '$100 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$5000' }
		],
		caption: `25 років на ринку сфери послуг. За підсумками року входить до рейтингу 
		топ-10 найеращих готелів світу.`,
		subCaption: 'Зможеш продати від <b>$530 000</b> до <b>$800 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 37,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на чотири родини К-4',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$125 000' },
			{ title: 'ЗАКЛАДНА', caption: '$110 000' },
			{ title: 'ЗАВДАТОК', caption: '$15 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$600' }
		],
		caption: `Котедж у передмісті. Ціна нижча за ринкову на 20% через переоформлення активу 
		з комерційної на житлову нерухомість.`,
		subCaption: 'Зможеш продати від <b>$100 000</b> до <b>$160 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 38,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Продається котедж на чотири родини К-4',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$80 000' },
			{ title: 'ЗАКЛАДНА', caption: '$64 000' },
			{ title: 'ЗАВДАТОК', caption: '$16 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$750' }
		],
		caption: `Квартира виставлена на продаж через колектора.`,
		subCaption: 'Зможеш продати від <b>$100 000</b> до <b>$160 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 39,
		type: bigAgreementTypes.REAL_ESTATE,
		name: 'Торговельний центр',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$60 000' },
			{ title: 'ЗАКЛАДНА', caption: '$0' },
			{ title: 'ЗАВДАТОК', caption: '$60 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$900' }
		],
		caption: `Виставлений на продаж сьогодні. Не вистачило управлінського досвіду сколотити команду, 
		залучити орендарів та зробити бізнес рентабельним. Власник банкрут. Банк розпродає майно.`,
		subCaption: 'Зможеш продати від <b>$40 000</b> до <b>$150 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	},
	{
		id: 40,
		type: bigAgreementTypes.BUSINESS,
		name: 'Франшиза на міжнародний готельний бізнес',
		info: [
			{ title: 'ВАРТІСТЬ', caption: '$500 000' },
			{ title: 'ЗАКЛАДНА', caption: '$400 000' },
			{ title: 'ЗАВДАТОК', caption: '$100 000' },
			{ title: 'ПАСИВНИЙ ДОХІД', caption: '$5 000' }
		],
		caption: `25 років на ринку сфери послуг. За підсумками року входить до рейтингу топ-10 
		найкращих готелів світу.`,
		subCaption: 'Зможеш продати від <b>$530 000</b> до <b>$800 000</b>. Використай шанс сам або продай іншому гравцю.',
		isSellAble: true
	}
];