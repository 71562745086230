export const videoList = [
	{
		id: 1,
		title: 'Загальні правила',
		src: 'videos/general-rules.MOV'
	},
	{
		id: 2,
		title: 'Домовленості',
		src: 'videos/agreement-rules.MOV'
	},
	{
		id: 3,
		title: 'Фінанси',
		src: 'videos/financial-rules.MOV'
	},
	{
		id: 4,
		title: 'Звітність',
		src: 'videos/financial-report.mp4'
	}
];