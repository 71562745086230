export const bigPathItemSize = {
	width: 170,
	height: 140,
	left: 0,
	top: 0
};

export const smallPathItemSize = {
	width: 170,	
	height: 140,
	left: 200,
	top: 200
};

export const smallPathTypes = {
	MARKET: 'market'
};

export const fishkaCoordinates = {
	smallPath: {
		left: '-220px',
		top: '700px'
	},
	bigPath: {
		left: '1540px',
		top: '1570px'
	}
};