const smallPathColorList = {
	green: '#38761d',
	violet: '#a64b79',
	yellow: '#ffff00',
	blue: '#3c78d8',
	blue_dark: '#351c75',
	red: '#cc0000',
	black: '#000000',
	white: '#ffffff'
};

export const smallPath = [
	{
		id: 1,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 2,
		title: 'ЗАРОБІТНА ПЛАТА',
		color: smallPathColorList.black,
		backgroundColor: smallPathColorList.yellow
	},
	{
		id: 3,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 4,
		title: 'РИНОК',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.blue
	},
	{
		id: 5,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 6,
		title: 'ГРОШІ НА ВІТЕР',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.red
	},
	{
		id: 7,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 8,
		title: 'ДИТИНА',
		subTitle: 'Вітаємо! Додай в свій баланс витрат на дитину',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.violet
	},
	{
		id: 9,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 10,
		title: 'ЗАРОБІТНА ПЛАТА',
		color: smallPathColorList.black,
		backgroundColor: smallPathColorList.yellow
	},
	{
		id: 11,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 12,
		title: 'РИНОК',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.blue
	},
	{
		id: 13,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 14,
		title: 'ГРОШІ НА ВІТЕР',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.red
	},
	{
		id: 15,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 16,
		title: 'ЗВІЛЬНЕННЯ',
		subTitle: 'Сплати повну суму своїх витрат та пропусти 2 ходи',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.black
	},
	{
		id: 17,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 18,
		title: 'ЗАРОБІТНА ПЛАТА',
		color: smallPathColorList.black,
		backgroundColor: smallPathColorList.yellow
	},
	{
		id: 19,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 20,
		title: 'РИНОК',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.blue
	},
	{
		id: 21,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 22,
		title: 'ГРОШІ НА ВІТЕР',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.red
	},
	{
		id: 23,
		title: 'ШАНС',
		subTitle: 'велика або мала угода',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.green
	},
	{
		id: 24,
		title: 'БЛАГОДІЙНІСТЬ',
		subTitle: 'Можеш кидати 1 або 2 кубики наступні 3 ходи якщо зробиш внесок 10% від свого загального дoходу',
		color: smallPathColorList.white,
		backgroundColor: smallPathColorList.blue_dark
	}
];