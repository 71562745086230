export const professionCardTypes = {
	ACTIONS: 'ACTIONS',
	REAL_ESTATE: 'REAL_ESTATE',
	BUSINESS: 'BUSINESS',
	ARITHMETIC: 'ARITHMETIC',
	BUYED_DREAMS: 'BUYED_DREAMS',
	BUYED_BUSINESS: 'BUYED_BUSINESS',
	BUYED_CASH: 'BUYED_CASH'
};

export const professionCardItemTypes = {
	TWO_COLUMNS_LIST: 'TWO_COLUMNS_LIST',
	TWO_COLUMNS: 'TWO_COLUMNS',
	ONE_COLUMN_LIST: 'ONE_COLUMN_LIST'
};

export const itemStatuses = {
	DEFAULT: 'DEFAULT',
	UPDATED: 'UPDATED',
	INSERTED: 'INSERTED',
	REMOVED: 'REMOVED'
};