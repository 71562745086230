const bigPathColorList = {
	violet: '#ead1dc',
	blue: '#9fc5e8',
	yellow: '#ffff00',
	green: '#93c47d',
	white: '#ffffff'
};

export const bigPath = [
	{
		id: 1,
		title: 'Відвідаєш храм Ангкор-Ват у серці джунглів',
		price: '$150 000',
		src: 'img-1.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 2,
		title: 'Виробництво сонячних електростанцій ПД + $75 000, якщо викинеш 4,5 або 6',
		price: '$300 000',
		src: 'img-2.png',
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 3,
		title: 'Підкориш Еверест в компанії найкращих альпіністів світу',
		price: '$200 000',
		src: 'img-3.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 4,
		title: 'Ферма майнінгу криптовалют ПД + $8 000',
		price: '$220 000',
		src: 'img-4.png',
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 5,
		title: 'ДОХІД',
		price: '',
		src: 'img-5.png',
		backgroundColor: bigPathColorList.yellow
	},
	{
		id: 6,
		title: 'Виноробня у Франції з 175 га виноградників ПД + $9 500',
		price: '$300 000',
		src: 'img-6.png',
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 7,
		title: 'Притулок для безхатніх тварин',
		price: '$100 000',
		src: 'img-7.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 8,
		title: 'Переробка та утилізація відходів 25 000 акцій по 1$; якщо викинеш 6, отримаєш $500 000',
		price: '$25 000',
		src: null,
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 9,
		title: 'Нобелівська премія з літератури за написання найкоротшого оповідання',
		price: '$100 000',
		src: 'img-9.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 10,
		title: 'Форс-мажор ти втрачаєш усі активи (крім готівки). Повертайся на мале коло з новою професією',
		price: '',
		src: 'img-10.png',
		backgroundColor: bigPathColorList.green
	},
	{
		id: 11,
		title: 'Виробництво вантажних електрокарів ПД + $10 000',
		price: '$320 000',
		src: 'img-11.png',
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 12,
		title: 'Станеш послом доброї волі ООН',
		price: '$200 000',
		src: 'img-12.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 13,
		title: 'Мережа ресторанів іспанської кухні ПД + $8 000',
		price: '$225 000',
		src: 'img-13.png',
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 14,
		title: 'Участь в автоперегонах Ралі-марафон Дакар',
		price: '$150 000',
		src: 'img-14.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 15,
		title: 'Найбільший В2С-маркетплейс у Південній Америці ПД + $6000',
		price: '$200 000',
		src: 'img-15.png',
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 16,
		title: 'Премія TURING AWARD за розробки у сфері штучного інтелекту',
		price: '$230 000',
		src: 'img-16.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 17,
		title: 'Завод з виробництва модульних будинків ПД + $50 000 якщо викинеш 4,5 або 6',
		price: '$225 000',
		src: 'img-17.png',
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 18,
		title: 'Зіграєш головну роль у блокбастері Квентіна Тарантіно Назву картини вигадай сам',
		price: '$150 000',
		src: 'img-18.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 19,
		title: 'Аутсориснгова ІТ-компанія ІРО-акції 50000 акцій купиш по 1$, якщо викинеш на кубику 5 або 6 отримаєш $500 000',
		price: '$50 000',
		src: null,
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 20,
		title: 'Школа серфінгу у Сан-Себастьяні',
		price: '$200 000',
		src: 'img-20.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 21,
		title: 'ДОХІД',
		price: '',
		src: null,
		backgroundColor: bigPathColorList.yellow
	},
	{ 
		id: 22,
		title: 'Винахід ентомологів по збереженню бджіл',
		price: '$200 000',
		src: 'img-22.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 23,
		title: 'Університет біоінженерії ПД + $7000',
		price: '$250 000',
		src: null,
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 24,
		title: 'Відірвешся на Салвадорському карнавалі у Бразілії',		
		price: '$100 000',
		src: 'img-24.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 25,
		title: 'Шельфова вітрова електрична станція. Знайди партнера для купівлі бізнесу. ПД + $40 000',
		price: '$1 000 000 з кожного',
		src: null,
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 26,
		title: 'Тебе ошукали. Втрачаєш всю готівку. Час замислитися про страхування',
		price: '',
		src: null,
		backgroundColor: bigPathColorList.yellow
	},
	{
		id: 27,
		title: 'Озеленення планети. 300 га рідкісних хвойних дерев із Червоної книги',
		price: '$200 000',
		src: 'img-27.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 28,
		title: 'Міжнародний готельний бізнес ПД + $15 000',
		price: '$330 000',
		src: 'img-28.png',
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 29,
		title: 'Подорож на самоті на плавучому будиночку водами Керала, Індія',		
		price: '$150 000',
		src: 'img-29.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 30,
		title: 'Кол-центр для лідогенерації ПД + $6 000',
		price: '$120 000',
		src: null,
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 31,
		title: 'Видавничий центр із підтримкою талановитих авторів',
		price: '$150 000',
		src: 'img-31.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 32,
		title: 'Виробництво сировялених делікатесів ПД + $5000',		
		price: '$100 000',
		src: null,
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 33,
		title: 'Насолодишся фантастичними краєвидами під час Космічної подорожі',		
		price: '$250 000',
		src: 'img-33.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 34,
		title: 'БЛАГОДІЙНІСТЬ. Якщо зробиш внесок у благодійний фонд $100 000 ти матимеш право кидати 1,2 або 3 кубики до кінця гри.',
		price: '',
		src: null,
		backgroundColor: bigPathColorList.white
	},
	{
		id: 35,
		title: 'Мобільний бетонний завод ПД + $10000',
		price: '$300 000',
		src: null,
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 36,
		title: 'Відновиш стародавні сорти пшениці',
		price: '$120 000',
		src: 'img-36.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 37,
		title: 'ДОХІД',
		price: '',
		src: null,
		backgroundColor: bigPathColorList.yellow
	},
	{
		id: 38,
		title: 'Прогуляєшся пляжами Занзібара в Африці на заході сонця',
		price: '$170 000',
		src: 'img-38.jpg',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 39,
		title: 'Ремонт та обслуговування роботів та дронів ПД + $7 000',
		price: '$170 000',
		src: null,
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 40,
		title: 'SEO фірма для підлітків',
		price: '$150 000',
		src: 'img-40.jpg',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 41,
		title: 'Ярмарок фермерської продукції ПД + $25 000 якщо викинеш 3 та більше',
		price: '$160 000',
		src: null,
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 42,
		title: 'Податкова перевірка. Віддай половину своїх грошей у державну скарбницю',
		price: '',
		src: null,
		backgroundColor: bigPathColorList.yellow
	},
	{
		id: 43,
		title: 'Участь в реставрації робіт Сандро Боттічеллі в галереї Уффіці у Флоренції',
		price: '$100 000',
		src: 'img-43.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 44,
		title: 'Франшиза виробництва овечого сиру ПД + $7 000',
		price: '$200 000',
		src: null,
		backgroundColor: bigPathColorList.blue
	},
	{
		id: 45,
		title: 'Закохаєшся на великому базарі Mercado de San Miguel в Іспані',
		price: '$ 320 000',
		src: 'img-45.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 46,
		title: 'Розробка та виробництво вакцин ПД + $15 000',
		price: '$320 000',
		src: null,
		backgroundColor: bigPathColorList.blue
	},	
	{
		id: 47,
		title: 'Гранд на розробку проєкту з безпеки платежів криптовалютою',
		price: '$130 000',
		src: 'img-47.png',
		backgroundColor: bigPathColorList.violet
	},
	{
		id: 48,
		title: 'Мережа Барбершопів ПД + $6 000',
		price: '$130 000',
		src: null,
		backgroundColor: bigPathColorList.blue
	}
];