export const moneyInTheWindCardList = [
	{
		id: 1,
		info: `На блошиному ринку придбав різдвяну зірку за <b>$270</b>`,
		caption: ``
	},
	{
		id: 2,
		info: `Купи вже нові іграшки своїм дітям`,
		caption: `По <b>$50</b> на кожну дитину (якщо є діти)`
	},
	{
		id: 3,
		info: `Купи нові м'ячі для гольфу`,
		caption: `<b>$20</b>`
	},
	{
		id: 4,
		info: `Діти йдуть у коледж`,
		caption: `Плата за навчання <b>$1500</b> (якщо є діти)`
	},
	{
		id: 5,
		info: `Придбав в Клубі колекціонерів камертон. Ціеа дуже вдала`,
		caption: `<b>$1500</b>`
	},
	{
		id: 6,
		info: `Зробив ставку <b>$200</b> в букмекерській конторі "Ліга Чемпіонів" 
			на виграш улюбленої футбольної команди`,
		caption: ``
	},
	{
		id: 7,
		info: `Порушення правил паркування на місцях для інвалідів`,
		caption: `Штраф <b>$100</b>`
	},
	{
		id: 8,
		info: `Новий велосипед для прогулянок`,
		caption: `<b>$180</b>`
	},
	{
		id: 9,
		info: `Курив фотознімок молодого, проте перспективного фотографа за 
			<b>$150</b> з авторськими правами`,
		caption: `Робота цього автора через 20 років принесе капітал`
	},
	{
		id: 10,
		info: `Оптимізація витрат компанії. Звільнення 30% команди`,
		caption: `<b>$300</b> курси підвищення кваліфікації`
	},
	{
		id: 11,
		info: `Витратився на домашній кінотеатр. Сплати <b>$4000</b>`,
		caption: `(якщо розраховуєшся кредитною карткою, додай <b>$4000</b>) 
		до статті "кредитні картки" та додай <b>$120</b> до щомісячних 
		витрат)`
	},
	{
		id: 12,
		info: `Мобільний додаток з управління фінансами`,
		caption: `Вартість <b>$20</b>`
	},
	{
		id: 13,
		info: `Відпустка в сімейному колі`,
		caption: `<b>$2000</b>`
	},
	{
		id: 14,
		info: `MacBook накрився`,
		caption: `<b>$500</b> ремонт у сервісному центрі`
	},
	{
		id: 15,
		info: `Зламався водонагрівач. Придбай новий за <b>$450</b>`,
		caption: `Сплати майстру <b>$50</b> за встановлення`
	},
	{
		id: 16,
		info: `Пообідав в ресторані`,
		caption: `Залишив <b>$80</b>`
	},
	{
		id: 17,
		info: `Вирішив створити родину з коханою людиню. Міг би обійтись 
			у <b>$1000</b>`,
		caption: `Якщо маєш дітей у грі, доведеться викласти за весілля <b>$3000</b>`
	},
	{
		id: 18,
		info: `У дитини неправильний прикус. Треба ставити скоби. <b>$2000</b> 
			(якщо є діти)`,
		caption: `Якщо за гральним столом є лікар, віддай гроші йому`
	},
	{
		id: 19,
		info: `З'явилося бажання придбати нові сонцезахисні окуляри`,
		caption: `Сплати <b>$100</b>`
	},
	{
		id: 20,
		info: `Встанови кондиціонер в авто. Бо спекотно`,
		caption: `<b>$700</b>`
	},
	{
		id: 21,
		info: `Поїздка у відпустку на відомий курорт разом із сім'єю`,
		caption: `<b>$2000</b>`
	},
	{
		id: 22,
		info: `Потрібна нова гума на авто. <b>$300</b>`,
		caption: `Безпека дорожче`
	},
	{
		id: 23,
		info: `Твій ювілей! Вітаємо!`,
		caption: `Викладай <b>$200</b>`
	},
	{
		id: 24,
		info: `Кидай палити! Твої шкідливі звички коштують надто дорого`,
		caption: `Заплати <b>$20</b> за блок цигарок та <b>$2000</b> за 
			за профілактичний курс лікування від небезпечних захворювань`
	},
	{
		id: 25,
		info: `Продав другу по акції "вдвох дешевше" курс Фейс-фітнес 
			ліфтинг обличчя ін'єкцій`,
		caption: `Сплатив <b>$30</b>`
	},
	{
		id: 26,
		info: `Квитки у театр`,
		caption: `Лише <b>$100</b>`
	},
	{
		id: 27,
		info: `Нова, дорога цифрова фотокамера для справжнього фотохудожника. 
			Коштує чимало.`,
		caption: `Аж <b>$700</b>. Але ж які файні світлини виходять!`
	},
	{
		id: 28,
		info: `Відвідування гіпермаркету. Ти не на жарт захопився шопінгом і 
			витратив свій тижневий заробіток ПД * 0,25`,
		caption: `Якщо ПД менший $1000, заплати <b>$500</b>`
	},
	{
		id: 29,
		info: `Бездротові навушники Apple Air Pods Max`,
		caption: `<b>$300</b>`
	},
	{
		id: 30,
		info: `Відвідай виїзний футбольний матч улюбленої команди зі збірною 
			країни в чужому місті`,
		caption: `Поїздка обійдеться в <b>$100</b>`
	},
	{
		id: 31,
		info: `День народження! Повеселися з дітьми у Парку Розваг на <b>$100</b>`,
		caption: `(якщо є діти)`
	},
	{
		id: 32,
		info: `Відвідай концерт відомих рок-зірок`,
		caption: `<b>$100</b>`
	},
	{
		id: 33,
		info: `Зуби болять! Терміновий позаплановий візит до стоматолога. Нова 
			пломба`,
		caption: `Задоволення коштує <b>$100</b>. Чисти зуби двічі на день! 
			(якщо за градьним столом є лікарі, віддай гроші їм)`
	},
	{
		id: 34,
		info: `Час оновти гардероб`,
		caption: `Шопінг потягнув на <b>$500</b>`
	},
	{
		id: 35,
		info: `Купив за <b>$550</b> використаний Ернестом Хемінгуеєм квиток 
			з фестивалю Сен-Фермін, датований 1923 роком`,
		caption: ``
	},
	{
		id: 36,
		info: `Щорічна щустріч однокласників`,
		caption: `Цього разу <b>$250</b>`
	},
	{
		id: 37,
		info: `Спадщина від бабусі. Будиночок на 120 кв. м на відстані 60 км 
			від міста!`,
		caption: `Проект з переобладнання <b>$800</b>`
	},
	{
		id: 38,
		info: `Нова яхта!`,
		caption: `Лише <b>$1000</b> перший платіж і <b>$17000</b> у розстрочку 
			(додай собі <b>$17000</b> кредиту, як нову заборгованість, і ще <b>$340</b> 
			в витрати, як сплата відсотків по кредиту)`
	},
	{
		id: 39,
		info: `Час зайнятись спортом і почати відвідувати тренажерну залу. Пільговий 
			річний абонемент <b>$250</b>`,
		caption: `Шварцнеггером навряд чи станеш, проте м'язи зміцниш`
	},
	{
		id: 40,
		info: `Сплатив <b>$80</b> за курс "Reels-Fire". За миттєву підписку тобі 
			подарували Гайд "15 помилок в Reels"`,
		caption: ``
	}
];