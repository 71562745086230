const infoReducerActionTypes = {
	SET_USER: 'SET_USER',
	SET_GAME: 'SET_GAME',
	SET_PROTOCOL: 'SET_PROTOCOL',
	SET_NETWORK_STATUS: 'SET_NETWORK_STATUS',
	CLEAR_INFO_STORAGE: 'CLEAR_INFO_STORAGE',	
	SET_OWNER_DATA: 'SET_OWNER_DATA',
	SET_GAMER_TURN_DATA: 'SET_GAMER_TURN_DATA',
	SET_GAMER_TURN_PATH: 'SET_GAMER_TURN_PATH',
	
	CLEAR_INFO_GAMER_LIST: 'CLEAR_INFO_GAMER_LIST',
	CLEAR_INFO_STORAGE: 'CLEAR_INFO_STORAGE'
};

const userModelReducerActionTypes = {
	SET_SMALL_PATH: 'SET_SMALL_PATH',
	SET_BIG_PATH: 'SET_BIG_PATH',
	SET_USER_MODEL: 'SET_USER_MODEL',
	SET_DICE_VALUE: 'SET_DICE_VALUE',
	SET_DICE_COUNT: 'SET_DICE_COUNT',
	SET_FISHKA_STEP_PROCESS_VALUE: 'SET_FISHKA_STEP_PROCESS_VALUE',
	SET_FISHKA_POSITION: 'SET_FISHKA_POSITION',
	SET_FISHKA: 'SET_FISHKA',
	SET_COMMON_EVENTS: 'SET_COMMON_EVENTS',
	
	SET_CURRENT_AGREEMENT_CARD_ID_TYPE: 'SET_CURRENT_AGREEMENT_CARD_ID_TYPE',
	SET_CURRENT_AGREEMENT_CARD_CONTENT: 'SET_CURRENT_AGREEMENT_CARD_CONTENT',
	SET_CURRENT_AGREEMENT_CARD_IS_PRESENT: 'SET_CURRENT_AGREEMENT_CARD_IS_PRESENT',
	CLEAR_CURRENT_AGREEMENT_CARD: 'CLEAR_CURRENT_AGREEMENT_CARD',
	
	CLEAR_USER_MODEL_STORAGE: 'CLEAR_USER_MODEL_STORAGE'
};

const bigPathCardReducerActionTypes = {
	SET_DREAM: 'SET_DREAM',
	SET_PROFESSION: 'SET_PROFESSION',
	
	CLEAR_BIG_PATH_CARD_STORAGE: 'CLEAR_BIG_PATH_CARD_STORAGE'
};

export const ActionTypes = {
	...infoReducerActionTypes,
	...userModelReducerActionTypes,
	...bigPathCardReducerActionTypes
};